.light header, .light .header-btn{
    background-color: rgba(240,240,240,1);
    color: #333;
}
.light .header-btn:hover{
    background: rgb(200, 200, 200);
    color: #000;
}
.light .header-btn-selected{
    background-color: rgba(220,220,220,1);
}
.light #img-logo-dark{
    display: none;
}
.light #img-logo-light{
    display: block;
}
.light #hamburger-btn{
    color: #333;
}
.light #hamburger-btn:hover{
    color: black;
}

.dark header, .dark .header-btn{
    background-color: rgba(70,70,70,1);
    color: #ddd;
}
.dark .header-btn:hover{
    background: rgb(100, 100, 100);
    color: #fff;
}
.dark .header-btn-selected{
    background-color: rgba(90,90,90,1);
}
.dark #img-logo-light{
    display: none;
}
.dark #img-logo-dark{
    display: block;
}
.dark #hamburger-btn{
    color: #ddd;
}
.dark #hamburger-btn:hover{
    color: white;
}

header{
    display: flex;
    position: relative;
    justify-content: center;
    height: 70px;
    min-height: 70px;
    width: 100%;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    z-index: 100;
}

header :link{
    text-decoration: none;
}

#logo-wrap{
    flex-basis: 0px;
    min-width: 200px;
    width: 200px;
    height: 70px;
}

#logo-wrap img{
    height: 100%;
    opacity: 0.85;
    width: auto;
}
#logo-wrap img:hover{
    opacity: 1;
}


#header-btns-wrap{
    background: #4088dd;
    background-image: url("/media/sidebar_bg.png");
    background-size: cover;
    min-height: 20px;
}


#header-nav-block{
    display: flex;
}

.header-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 70px;
}


#header-btn-beta{
    position: relative;
    overflow: hidden;
}

#header-btn-beta:after{
    content: "BETA";
    position: absolute;
    pointer-events: none;
    width: 80px;
    height: 20px;
    background: #EE8E4A;
    top: 6px;
    left: -24px;
    text-align: center;
    font-size: 13px;
    padding-top: 1px;
    color: #fff;
    line-height: 20px;
    -ms-transform:rotate(-45deg);
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
}
#hamburger-btn{
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    height: 70px;
    width: 40px;
    cursor: pointer;
    font-size: 1.5em;
}

@media screen and (max-width: 1199px) {
    .light .header-btn{
        border-top: 1px solid rgba(210,210,210,1);
    }
    .light .header-btn-last{
        border-bottom: 1px solid rgba(210,210,210,1);
    }
    .dark .header-btn{
        border-top: 1px solid rgba(100,100,100,1);
    }
    .dark .header-btn-last{
        border-bottom: 1px solid rgba(100,100,100,1);
    }
    #logo-wrap{
        position: relative;
    }
    #header-btns-wrap{
        display: block;
        position: fixed;
        min-width: 320px;
        padding-left: 3px;
        padding-bottom: 3px;
        box-shadow: 0 0 5px rgba(0,0,0,0.7);
        transition: right 0.15s, opacity 0.15s;
        height: 100vh;
    }
    .sidebar-visible{
        opacity: 1;
        right: 0;
    }
    .sidebar-hidden{
        opacity: 0;
        right: -400px;
    }
    #pusher{
        display: block;
        background: none;
        min-height: 70px;
        max-height: 70px;
    }
    #header-nav-block{
        flex-direction: column;
    }
    .header-btn{
        justify-content: center;
        align-items: flex-start;
        padding: 0px 10px;
        font-size: 1.2em;
        width: 100%;
    }
    #hamburger-btn{
        display: flex;
    }
}
@media screen and (min-width: 1200px) {
    .light .header-btn{
        border-left: 1px solid rgba(210,210,210,1);
    }
    .light .header-btn-last{
        border-right: 1px solid rgba(210,210,210,1);
    }
    .dark .header-btn{
        border-left: 1px solid rgba(100,100,100,1);
    }
    .dark .header-btn-last{
        border-right: 1px solid rgba(100,100,100,1);
    }
    #header-btns-wrap{
        display: flex;
    }
    #logo-wrap{
        position: absolute;
        left:50px;
    }
    #header-nav-block{
        flex-direction: row;
    }
    #pusher{
        display: none;
    }
    .header-btn{
        padding: 0px 15px;
        font-size: 1.5rem;
        max-width: 200px;
        justify-content: center;
    }
    #hamburger-btn{
        display: none;
    }
}