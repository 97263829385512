
.donationsblock{
    display: flex;
    flex-direction: column;
    text-decoration:none;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    background: radial-gradient(circle,rgb(0, 140, 220) 50%,rgb(0, 100, 190));
    color: white;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    z-index: 30;
}

.donationsblock:hover{
    background: radial-gradient(circle,rgb(0, 140, 240) 50%,rgb(0, 100, 200));
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

#donationscont{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 50%;
    min-width: 280px;
    max-width: 1270px;
}

#donationstext{
    font-size: 0.9em;
    margin-bottom: 0.1em;
    line-height: 0.8em;
}

#donationstitle{
    font-size: 1.8em;
    line-height: 1em;
    margin: 0.5em 0em;
}

.donationsline{
    margin: 0.6em 0em;
}

#donationsbar{
    display: flex;
    justify-content: center;
    background: white;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
}