body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    height: 100vh;
}

*{
    box-sizing: border-box;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}


.light{
    background-color: rgba(240,240,240,1);
    color: black;
}
.light div::-moz-selection{
    background:#ddd;
}
.light div::selection{
    background:#ddd;
}

.dark{
    background-color: rgba(40,40,40,1);
    color: #ddd;
}

.serverswrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 5px;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    z-index: 10;
}

.loadingFirst{
    opacity: 0.4;
}

footer{
    color: #777;
    font-size: 0.8em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

footer a{
    padding: 0px 50px;
    text-decoration: none;
    color: inherit;
}

.spinner{
    border: 16px solid #888; 
    border-top: 16px solid transparent;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.spinnerhidder{
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-76px);
            transform: translateY(-76px);
    -webkit-animation: 1s linear 1s 1 normal forwards running modopacity;
            animation: 1s linear 1s 1 normal forwards running modopacity;
    opacity: 0;
    z-index: 1000;
    pointer-events: none;
}

@-webkit-keyframes modopacity{
    from{opacity: 0}
    to{opacity: 1}
}

@keyframes modopacity{
    from{opacity: 0}
    to{opacity: 1}
}

@media screen and (max-width: 700px) {
    footer{display: none;}
}

.light header, .light .header-btn{
    background-color: rgba(240,240,240,1);
    color: #333;
}
.light .header-btn:hover{
    background: rgb(200, 200, 200);
    color: #000;
}
.light .header-btn-selected{
    background-color: rgba(220,220,220,1);
}
.light #img-logo-dark{
    display: none;
}
.light #img-logo-light{
    display: block;
}
.light #hamburger-btn{
    color: #333;
}
.light #hamburger-btn:hover{
    color: black;
}

.dark header, .dark .header-btn{
    background-color: rgba(70,70,70,1);
    color: #ddd;
}
.dark .header-btn:hover{
    background: rgb(100, 100, 100);
    color: #fff;
}
.dark .header-btn-selected{
    background-color: rgba(90,90,90,1);
}
.dark #img-logo-light{
    display: none;
}
.dark #img-logo-dark{
    display: block;
}
.dark #hamburger-btn{
    color: #ddd;
}
.dark #hamburger-btn:hover{
    color: white;
}

header{
    display: flex;
    position: relative;
    justify-content: center;
    height: 70px;
    min-height: 70px;
    width: 100%;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    z-index: 100;
}

header :link{
    text-decoration: none;
}

#logo-wrap{
    flex-basis: 0px;
    min-width: 200px;
    width: 200px;
    height: 70px;
}

#logo-wrap img{
    height: 100%;
    opacity: 0.85;
    width: auto;
}
#logo-wrap img:hover{
    opacity: 1;
}


#header-btns-wrap{
    background: #4088dd;
    background-image: url("/media/sidebar_bg.png");
    background-size: cover;
    min-height: 20px;
}


#header-nav-block{
    display: flex;
}

.header-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 70px;
}


#header-btn-beta{
    position: relative;
    overflow: hidden;
}

#header-btn-beta:after{
    content: "BETA";
    position: absolute;
    pointer-events: none;
    width: 80px;
    height: 20px;
    background: #EE8E4A;
    top: 6px;
    left: -24px;
    text-align: center;
    font-size: 13px;
    padding-top: 1px;
    color: #fff;
    line-height: 20px;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
}
#hamburger-btn{
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    height: 70px;
    width: 40px;
    cursor: pointer;
    font-size: 1.5em;
}

@media screen and (max-width: 1199px) {
    .light .header-btn{
        border-top: 1px solid rgba(210,210,210,1);
    }
    .light .header-btn-last{
        border-bottom: 1px solid rgba(210,210,210,1);
    }
    .dark .header-btn{
        border-top: 1px solid rgba(100,100,100,1);
    }
    .dark .header-btn-last{
        border-bottom: 1px solid rgba(100,100,100,1);
    }
    #logo-wrap{
        position: relative;
    }
    #header-btns-wrap{
        display: block;
        position: fixed;
        min-width: 320px;
        padding-left: 3px;
        padding-bottom: 3px;
        box-shadow: 0 0 5px rgba(0,0,0,0.7);
        -webkit-transition: right 0.15s, opacity 0.15s;
        transition: right 0.15s, opacity 0.15s;
        height: 100vh;
    }
    .sidebar-visible{
        opacity: 1;
        right: 0;
    }
    .sidebar-hidden{
        opacity: 0;
        right: -400px;
    }
    #pusher{
        display: block;
        background: none;
        min-height: 70px;
        max-height: 70px;
    }
    #header-nav-block{
        flex-direction: column;
    }
    .header-btn{
        justify-content: center;
        align-items: flex-start;
        padding: 0px 10px;
        font-size: 1.2em;
        width: 100%;
    }
    #hamburger-btn{
        display: flex;
    }
}
@media screen and (min-width: 1200px) {
    .light .header-btn{
        border-left: 1px solid rgba(210,210,210,1);
    }
    .light .header-btn-last{
        border-right: 1px solid rgba(210,210,210,1);
    }
    .dark .header-btn{
        border-left: 1px solid rgba(100,100,100,1);
    }
    .dark .header-btn-last{
        border-right: 1px solid rgba(100,100,100,1);
    }
    #header-btns-wrap{
        display: flex;
    }
    #logo-wrap{
        position: absolute;
        left:50px;
    }
    #header-nav-block{
        flex-direction: row;
    }
    #pusher{
        display: none;
    }
    .header-btn{
        padding: 0px 15px;
        font-size: 1.5rem;
        max-width: 200px;
        justify-content: center;
    }
    #hamburger-btn{
        display: none;
    }
}
.subheaderwrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 35;
}

.subheaderwrap:hover{
    opacity: 0.9;
}

.subheaderwrap a{
    text-decoration:none;
    width:100%;
    padding: 5px;
}

.subheadermsg{
    display: flex;
    justify-content: center;
    width:100%;
}
/* flex-grow=1 and canvas resize is giving problems, using width: 100% deforms*/

.mappreviewcanvas{
    /*width: 100%;*/
}
.light .serverwrap, .light .offlineserverwrap{
    background-color: #fff;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
}
.light .servertablewrap{
    background-color: #ddd;
    font-size: 0.8em;
}
.light .ReactTable{
    color: #333;
}

.dark .serverwrap, .dark .offlineserverwrap{
    background-color: #444;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.dark .servertablewrap{
    background-color: #555;
    font-size: 0.9em;
}
.dark .ReactTable{
    color: #ddd;
}

.serverwrap, .offlineserverwrap{
    box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 5px;
    cursor: pointer;
}

.offlineserverwrap{
    cursor: default;
}

.serverwrap-offline{
    opacity: 0.5;
}

.offlineimgwrap img{
    max-width: 100%;
    height: auto;
}

.serverwrap:last-child, .offlineserverwrap:last-child{
    margin-bottom: 20px;
}

.serverwrap:hover, .offlineserverwrap:hover{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.expanded .playerstablewrap{
    visibility: visible;
    margin-top: 5px;
    height: auto;
}

.innerwrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mapwrap{
    position: relative;
    display: flex;
    flex: 1 1;
    background: rgb(240, 240, 240);
    border: 1px solid #999;
    box-sizing: border-box;
}

.servertablewrap{
    display: flex;
    flex: 1 1;
    max-width: 700px;
    overflow: auto;
    margin: 10px 0px;
    box-sizing: border-box;
}

.playerstablewrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    visibility: hidden;
    height: 0px;
    overflow: auto;
}

.infowrap{
    display: flex;
    flex-direction: column;
    padding: 5px 25px;
    box-sizing: border-box;
}

.servername{
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.mapname{
    font-size: 1.2rem;
}

.utwrap{
    color: #888;
    font-size: 0.7em;
    align-self: flex-end;
}

.utwrap.active{
    color:rgb(0, 160, 60);
}
.utwrap.inactive{
    color:rgb(255, 20, 20);
    font-size: 0.8em;
}

.graphwrap{
    position: relative;
    box-sizing: border-box;
    padding: 5px 0px;
}


@media screen and (max-width: 700px) {
    .mapwrap{
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .graphwrap{
        padding-left: 0px;
        min-width: 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 1300px) {
    .innerwrap{
        flex-wrap: wrap;
    }
    .infowrap{
        order: 1;
        width: 100%;
        max-width: 100%;
        padding: 5px;
    }
    .mapwrap{
        order: 2;
        min-width: 50%;
        max-width: 50%;
    }
    .servertablewrap{
        max-width: 100%;
        width: 100%;
    }
    .graphwrap{
        order: 3;
        min-width: 50%;
        max-width: 50%;
        padding: 5px 0px 0px 10px;
    }
}

@media screen and (min-width: 1301px) and (max-width:1400px){
    .graphwrap{
        width: 300px;
    }
}

@media screen and (min-width: 1401px){
    .graphwrap{
        width: 400px;
    }
}


.donationsblock{
    display: flex;
    flex-direction: column;
    text-decoration:none;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    background: -webkit-radial-gradient(circle,rgb(0, 140, 220) 50%,rgb(0, 100, 190));
    background: radial-gradient(circle,rgb(0, 140, 220) 50%,rgb(0, 100, 190));
    color: white;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    z-index: 30;
}

.donationsblock:hover{
    background: -webkit-radial-gradient(circle,rgb(0, 140, 240) 50%,rgb(0, 100, 200));
    background: radial-gradient(circle,rgb(0, 140, 240) 50%,rgb(0, 100, 200));
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

#donationscont{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 50%;
    min-width: 280px;
    max-width: 1270px;
}

#donationstext{
    font-size: 0.9em;
    margin-bottom: 0.1em;
    line-height: 0.8em;
}

#donationstitle{
    font-size: 1.8em;
    line-height: 1em;
    margin: 0.5em 0em;
}

.donationsline{
    margin: 0.6em 0em;
}

#donationsbar{
    display: flex;
    justify-content: center;
    background: white;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
}
#weekstatswrap{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 5px;
    margin-bottom: 15px;
    margin-top: 20px;
}

#weekstatsinnerwrap{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1354px;
    padding-top: 15px;
    padding-top: 10px;
}

#weekstatstitle{
    font-size: 1.4em;
    align-self: center;
    padding-bottom: 15px;
}

#weekstatsinnerwrap:hover{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

#graphswrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.doughwrap{
    padding-bottom: 20px;
}
