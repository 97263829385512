.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}


.light{
    background-color: rgba(240,240,240,1);
    color: black;
}
.light div::selection{
    background:#ddd;
}

.dark{
    background-color: rgba(40,40,40,1);
    color: #ddd;
}

.serverswrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 5px;
    transition: opacity 0.2s;
    z-index: 10;
}

.loadingFirst{
    opacity: 0.4;
}

footer{
    color: #777;
    font-size: 0.8em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

footer a{
    padding: 0px 50px;
    text-decoration: none;
    color: inherit;
}

.spinner{
    border: 16px solid #888; 
    border-top: 16px solid transparent;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinnerhidder{
    position: fixed;
    top: 50%;
    transform: translateY(-76px);
    animation: 1s linear 1s 1 normal forwards running modopacity;
    opacity: 0;
    z-index: 1000;
    pointer-events: none;
}

@keyframes modopacity{
    from{opacity: 0}
    to{opacity: 1}
}

@media screen and (max-width: 700px) {
    footer{display: none;}
}
