#weekstatswrap{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 5px;
    margin-bottom: 15px;
    margin-top: 20px;
}

#weekstatsinnerwrap{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1354px;
    padding-top: 15px;
    padding-top: 10px;
}

#weekstatstitle{
    font-size: 1.4em;
    align-self: center;
    padding-bottom: 15px;
}

#weekstatsinnerwrap:hover{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

#graphswrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.doughwrap{
    padding-bottom: 20px;
}