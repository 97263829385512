.light .serverwrap, .light .offlineserverwrap{
    background-color: #fff;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
}
.light .servertablewrap{
    background-color: #ddd;
    font-size: 0.8em;
}
.light .ReactTable{
    color: #333;
}

.dark .serverwrap, .dark .offlineserverwrap{
    background-color: #444;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.dark .servertablewrap{
    background-color: #555;
    font-size: 0.9em;
}
.dark .ReactTable{
    color: #ddd;
}

.serverwrap, .offlineserverwrap{
    box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 5px;
    cursor: pointer;
}

.offlineserverwrap{
    cursor: default;
}

.serverwrap-offline{
    opacity: 0.5;
}

.offlineimgwrap img{
    max-width: 100%;
    height: auto;
}

.serverwrap:last-child, .offlineserverwrap:last-child{
    margin-bottom: 20px;
}

.serverwrap:hover, .offlineserverwrap:hover{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.expanded .playerstablewrap{
    visibility: visible;
    margin-top: 5px;
    height: auto;
}

.innerwrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mapwrap{
    position: relative;
    display: flex;
    flex: 1 1;
    background: rgb(240, 240, 240);
    border: 1px solid #999;
    box-sizing: border-box;
}

.servertablewrap{
    display: flex;
    flex: 1 1;
    max-width: 700px;
    overflow: auto;
    margin: 10px 0px;
    box-sizing: border-box;
}

.playerstablewrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    visibility: hidden;
    height: 0px;
    overflow: auto;
}

.infowrap{
    display: flex;
    flex-direction: column;
    padding: 5px 25px;
    box-sizing: border-box;
}

.servername{
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.mapname{
    font-size: 1.2rem;
}

.utwrap{
    color: #888;
    font-size: 0.7em;
    align-self: flex-end;
}

.utwrap.active{
    color:rgb(0, 160, 60);
}
.utwrap.inactive{
    color:rgb(255, 20, 20);
    font-size: 0.8em;
}

.graphwrap{
    position: relative;
    box-sizing: border-box;
    padding: 5px 0px;
}


@media screen and (max-width: 700px) {
    .mapwrap{
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .graphwrap{
        padding-left: 0px;
        min-width: 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 1300px) {
    .innerwrap{
        flex-wrap: wrap;
    }
    .infowrap{
        order: 1;
        width: 100%;
        max-width: 100%;
        padding: 5px;
    }
    .mapwrap{
        order: 2;
        min-width: 50%;
        max-width: 50%;
    }
    .servertablewrap{
        max-width: 100%;
        width: 100%;
    }
    .graphwrap{
        order: 3;
        min-width: 50%;
        max-width: 50%;
        padding: 5px 0px 0px 10px;
    }
}

@media screen and (min-width: 1301px) and (max-width:1400px){
    .graphwrap{
        width: 300px;
    }
}

@media screen and (min-width: 1401px){
    .graphwrap{
        width: 400px;
    }
}
