.subheaderwrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 35;
}

.subheaderwrap:hover{
    opacity: 0.9;
}

.subheaderwrap a{
    text-decoration:none;
    width:100%;
    padding: 5px;
}

.subheadermsg{
    display: flex;
    justify-content: center;
    width:100%;
}